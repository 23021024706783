import React from "react";
import "./Navigation.css";



const Navigation = (props) => {   


  const handleClickAbout = () => {
    console.log("hallihallo")
    props.scrollHandler("About")
  }

  const contactHandleClick = () => {
    props.scrollHandler("Contact")

   
  }
  const diHandleClick = () => {
    props.scrollHandler("dienstleistung")
  }

  return (
    <div className="absolute align-middle top-0 left-0 z-50 text-white w-full p-5 ">
      <div className="flex justify-between items-center">
        <div className="text-3xl ml-24"> Renovering </div>
        <div className="hidden  md:flex " >
          <div onClick={handleClickAbout} className="border-b-2 border-transparent  transition ease-in duration-700 mr-16 cursor-pointer hover:border-renovering-rot">
           Über Uns
          </div>
          <div onClick={diHandleClick} className="mr-16 cursor-pointer border-b-2 border-transparent  transition ease-in duration-700 mr-16 cursor-pointer hover:border-renovering-rot">Dienstleistungen</div>
          <div onClick={contactHandleClick} className="cursor-pointer mr-16 cursor-pointer border-b-2 border-transparent  transition ease-in duration-700 mr-16 cursor-pointer hover:border-renovering-rot">Kontakt</div>
        </div>
      </div>
    </div>
  );
};
export default Navigation;
