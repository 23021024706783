import React from 'react'
import gmail from "./../images/mail.svg";

const Dl_Section = ({ Logo, titel, text }) => {
    return (
        <div className="ml-16 mr-16">
        <div className="  pl-12 pr-12 mt-20">
          <div className="w-16 mx-auto">  <div className="w-16 ">
          <img src={Logo} alt="" />
        </div>
        </div>
        </div>
        <div className=" font-bold font-medium mt-4">
          <h2>{titel}</h2>
        </div>
        <div className="  font-medium mb-10">
          <h2>{text}</h2>
        </div>  
        </div>
    )
}

export default Dl_Section
