import React from "react";
import "./Dienstleistungen.js";
import Dl_Section from "./Dl_Section.js";
import build from "./../images/build-outline.svg"
import time from "./../images/time-outline.svg"
import refresh from "./../images/refresh-outline.svg"
import checkmark from "./../images/checkmark-done-outline.svg"


const Dienstleistungen = () => {
  return (
    <div className="text-center">
     <h1 className=" p-12 border-b  text-center text-2xl	md:text-4xl ">Dienstleistungen</h1>
     <div className=" lg:flex justify-around">
      <Dl_Section Logo={time} className="sm:w-1/4" titel="Design" text="Ist das Design ihrer Seite bereits etwas in die Jahre gekommen? Wir erstellen zeitgemässes Webdesign welches den heutigen Ansprüchen gerecht wird und ihrem Webauftritt neuen Glanz bringt. "></Dl_Section>
      <Dl_Section Logo={build}className="sm:w-1/4" titel="Swiss Made" text="Ihr Webauftritt ist wichtig. Wir sind der Meinung dass vorgefertigete Template Systeme die falsche Wahl sind. Wir erstellen und renovieren ihre Seite nach Mass und mit Liebe zum Detail."></Dl_Section>
      <Dl_Section Logo={refresh} className="sm:w-1/4" titel="Umsetzung" text="In den letzten Jahren hat sich die Webentwicklung drastisch verändert. War noch vor ein paar Jahren Wordpress oder Joomla im Trend, hat sich die Technik nun in eine andere Richtung entwickelt. Dank Javascript Frameworks und Headless CMS kann man heute schnell und konstengünstig eine profesionelle Seite entwicklen."></Dl_Section>
      <Dl_Section Logo={checkmark} className="sm:w-1/4" titel="Mobile" text="Rund achtzig Prozent der Besucher einer Webseite benutzten heute mobile Geräte. Deshalb muss der Fokus auf einem responsiven Design liegen, welches sich allen Geräten anpasst -vom Handy über das Tablet bis zum Desktop. "></Dl_Section>
      </div>
    </div>
  );
};

export default Dienstleistungen;
