import React from "react";
import bild from "../images/stefan-stefancik-5p_7M5MP2Iw-unsplash.jpg";

const About = () => {
  return (
    <div style={{ backgroundColor: "#F8F9FA" }} className="sm:flex p-8 md:items-center">
      <div className="sm:w-1/2">
        <img src={bild} className="xl:p-24 " alt="Norway"></img>
      </div>
      <div className="xl:p-24  sm:w-1/2 pl-8 mt-10 md:mt-0">
        <span className="text-4xl font-bold ">Über  </span>{" "}
        <span className="text-4xl ">Uns</span>
        <div className="text-2xl mb-2">
          <span className="text">Wir </span>{" "}
          <span className="text-renovering-rot ">
            {" "}
             sind eine junge Firma aus Luzern. 
          </span>
        </div>
        <div>
        Unser Ziel ist es, qualitativ hochstehende Webseiten zu erstellen. Wir sind der Meinung, dass Webdesign ein Handwerk ist und benutzen die besten und modernsten Werkzeuge. Stangenware ist uns suspekt. Wir fokussieren uns auf die Erstellung kleiner bis mittlerer Webseiten und freuen uns auf ihre Kontaktaufnahme. 
        </div>
      </div>
    </div>
  );
};

export default About;
